.liveStream {
  font-size: 10px;
  position: relative;
  z-index: 10; }
  .liveStream span {
    padding: 8px; }
  .liveStream .live {
    color: white;
    background-color: red;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .liveStream .time {
    color: white;
    background-color: rgba(255, 255, 255, 0.3);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .liveStream .eye {
    color: white; }
