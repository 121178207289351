.MacbookAir {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .MacbookAir .Frame .content .sliderBox {
    pointer-events: all;
    box-sizing: border-box; }
  .MacbookAir .react-player {
    position: absolute;
    top: 0;
    margin: auto;
    z-index: -100;
    overflow: hidden; }
  .MacbookAir .mainContent {
    /* made titlebar position relative to fit this under without padding */
    /* padding-top: 22px; */
    z-index: 10;
    pointer-events: none; }
  .MacbookAir .sliderBox {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: white;
    height: 100%; }
  .MacbookAir .clouds {
    /*  made 0, was -200, because clouds were behind app-frame-box thing */
    z-index: 0;
    position: fixed;
    min-width: 100%;
    min-height: 100%; }
  .MacbookAir .emoji-slider .emoji {
    height: 45px;
    width: 45px;
    background-size: cover; }
  .MacbookAir .emoji-slider-vert {
    display: flex;
    flex-direction: column;
    align-items: stretch; }
    .MacbookAir .emoji-slider-vert .slider {
      flex: 1; }
  .MacbookAir .emoji-slider-horiz {
    display: flex;
    flex-direction: row;
    align-items: stretch; }
    .MacbookAir .emoji-slider-horiz .slider {
      flex: 1; }
    .MacbookAir .emoji-slider-horiz .left {
      margin-right: 5px; }
  .MacbookAir .top {
    margin-bottom: 15px; }
  .MacbookAir .cloud-emoji {
    background-image: url(https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/macbookAir/cloud.png); }
  .MacbookAir .gust-emoji {
    background-image: url(https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/macbookAir/gust.png); }
