.home-page-modal .content {
  pointer-events: all; }

.loc-content {
  display: flex;
  flex-direction: column;
  color: white;
  flex: 1; }
  .loc-content .loc-img {
    width: 300px;
    height: 300px;
    background-size: cover; }
  .loc-content .loc-container {
    padding: 0px 20px 0px 20px;
    box-sizing: border-box;
    flex: 1; }
  .loc-content .loc-txt {
    height: 100%;
    line-height: 1.5em;
    overflow: auto; }
    .loc-content .loc-txt div {
      padding-bottom: 10px; }
    .loc-content .loc-txt .loc-title {
      font-size: 20px;
      padding-top: 20px; }
    .loc-content .loc-txt .loc-stars {
      font-size: 11px; }
    .loc-content .loc-txt .loc-description {
      font-size: 11px;
      line-height: 1.6em;
      padding-bottom: 20px; }
  .loc-content.mobile {
    color: black; }
