.Header {
  box-shadow: 0px 3px 20px 0px #ffffff16; }

.Blinds .react-p5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  .Blinds .react-p5 canvas {
    width: 100%; }

.Blinds #babylon-canvas {
  width: 99.8%; }

.Blinds .window {
  box-shadow: 0px 0px 10px 0px #ffffff26; }

.Blinds .windowDiv {
  position: relative; }
  .Blinds .windowDiv .background {
    position: absolute;
    width: 160px;
    height: 160px;
    background-repeat: repeat; }
  .Blinds .windowDiv .windowFrame {
    background-image: url(https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/blinds/blinds.png);
    width: 160px;
    opacity: .7;
    height: 100px;
    top: 0;
    background-size: 113% 100%;
    background-position: -11px -20px;
    position: absolute;
    background-repeat: no-repeat;
    transition: height 2s;
    pointer-events: all; }
    .Blinds .windowDiv .windowFrame:hover {
      height: 290px; }
