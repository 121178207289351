.JungleGyms {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: black; }
  .JungleGyms canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .JungleGyms video.gym {
    position: absolute;
    visibility: hidden; }
  .JungleGyms .react-player {
    /* position: fixed; */
    margin: auto;
    overflow: hidden; }
