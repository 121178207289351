.Gallery {
  background-image: url(https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/gallery/galaxy.png);
  background-size: cover;
  background-position: center;
  pointer-events: none; }
  .Gallery .loadingclass {
    position: absolute;
    top: 0;
    left: 0;
    display: none; }
  .Gallery .outerFrame {
    pointer-events: none;
    box-shadow: 0px 0px 20px #000000;
    /* #6c6c6c; */
    background: transparent;
    position: absolute; }
  .Gallery .Frame {
    pointer-events: none; }
    .Gallery .Frame .titlebar {
      pointer-events: all; }
  .Gallery .clouds {
    /*  made 0, was -200, because clouds were behind app-frame-box thing */
    z-index: 0;
    position: fixed;
    min-width: 100%;
    min-height: 100%; }
  .Gallery .MFA-deets {
    text-align: center;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1; }
    .Gallery .MFA-deets div a {
      text-decoration: none;
      color: black; }
  .Gallery .Bar {
    padding: 6px;
    font-size: 30px; }
  .Gallery .WineBar {
    padding-top: 15px; }
  .Gallery .DJStation {
    padding: 6px;
    font-size: 30px; }
  .Gallery .canvas {
    pointer-events: all; }
  .Gallery .pools .poolContainer .pool {
    width: 300px;
    pointer-events: all;
    height: 300px;
    background-image: url(https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/gallery/waves.gif);
    background-size: cover; }
  .Gallery .room-info {
    padding: 10px;
    color: black;
    background-color: white;
    height: 200px; }
    .Gallery .room-info h3 {
      font-size: 14px; }
    .Gallery .room-info p {
      font-size: 10px; }
  .Gallery .MiniMap .otherAvatarsMiniMap {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px; }
    .Gallery .MiniMap .otherAvatarsMiniMap .mini-avatars {
      position: relative;
      width: 200px;
      height: 200px; }
      .Gallery .MiniMap .otherAvatarsMiniMap .mini-avatars .emojiMiniMap {
        position: absolute;
        font-size: 12px;
        margin-top: -6px;
        margin-left: -6px;
        width: 20px;
        height: 20px; }
  .Gallery .react-p5 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: all; }

@media only screen and (max-width: 400px) {
  .HomePage .loading {
    font-size: 30px; } }
