.Mars.Sketch {
  overflow: auto; }

.Mars {
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1325px;
  min-height: 716px;
  background-color: black;
  position: relative;
  background-image: url(https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/mars/grid.jpg);
  background-repeat: repeat;
  background-size: 350px 350px; }
  .Mars .Mars-Desktop {
    width: 100%;
    height: 100%;
    overflow: auto; }
  .Mars .Frame .content .tank {
    pointer-events: all; }
  .Mars .fullContent {
    width: 100%;
    height: 100%; }
  .Mars .bigCrater {
    background-image: url(https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/mars/pool.png);
    width: 100%;
    background-repeat: no-repeat; }
  .Mars .diving {
    background-image: url(https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/mars/diving2.gif);
    background-size: 100% 100%; }
  .Mars .smallCrater {
    background-image: url(https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/mars/crater.png);
    height: 100%;
    width: 100%;
    background-position: cover; }
    .Mars .smallCrater div {
      border-radius: 50%;
      opacity: .3;
      height: 50%;
      width: 51%;
      position: relative;
      overflow: hidden;
      color: red;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%); }
  .Mars .marsCover {
    background-color: rgba(0, 0, 0, 0.3); }
  .Mars .blueTileDiv {
    height: 100%;
    width: 100%;
    background-repeat: repeat; }
  .Mars .blueChair {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/mars/pool/yellowchair.png); }
  .Mars .umbrellas {
    position: absolute;
    width: 100%;
    color: white;
    font-size: 36px;
    text-align: center;
    overflow: hidden;
    background-color: black;
    background-size: 300% 100%;
    background-image: url(https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/mars/waves.gif); }
    .Mars .umbrellas .waterCover {
      position: absolute;
      top: -10;
      opacity: 0.2; }
    .Mars .umbrellas div {
      width: 100%;
      margin-top: 10px;
      margin-left: 1px; }
      .Mars .umbrellas div span {
        transform-origin: 0 0;
        float: left;
        height: 36px; }
        .Mars .umbrellas div span .rot90 {
          transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg); }
        .Mars .umbrellas div span .rotNeg90 {
          transform: rotate(-90deg);
          -webkit-transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          -o-transform: rotate(-90deg); }
      .Mars .umbrellas div span.water {
        width: 36px;
        height: 36px; }
  .Mars .MuiTypography-body1 {
    height: 100px;
    color: white;
    background: transparent; }
  .Mars .swimSign {
    padding-top: 40px;
    font-weight: bold; }
  .Mars .tank {
    width: 100%;
    height: 100%;
    background-color: white;
    background-repeat: repeat; }
    .Mars .tank button {
      height: 100%;
      font-family: Arial;
      background-color: white;
      border: none; }
    .Mars .tank button:not(:last-child) {
      border-right: 1px solid black; }
