.Xfinity {
  background-color: white; }
  .Xfinity .balloon {
    background-image: url(https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/loop/waves.png); }
  .Xfinity .mouse {
    margin-top: 20px;
    margin-left: 27px; }
  .Xfinity .mouse-icon {
    width: 25px;
    height: 45px;
    border: 2px solid white;
    border-radius: 15px;
    cursor: pointer;
    text-align: center; }
  .Xfinity .mouse-wheel {
    height: 6px;
    margin: 2px auto 0;
    display: block;
    width: 3px;
    background-color: white;
    border-radius: 50%;
    -webkit-animation: 3.2s ease infinite wheel-up-down;
    -moz-animation: 3.2s ease infinite wheel-up-down;
    animation: 3.2s ease infinite wheel-up-down; }

@-webkit-keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0; }
  15% {
    opacity: 1; }
  50% {
    margin-top: 20px;
    opacity: 0; }
  65% {
    opacity: 1; }
  100% {
    margin-top: 2px;
    opacity: 0; } }

@-moz-keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0; }
  15% {
    opacity: 1; }
  50% {
    margin-top: 20px;
    opacity: 0; }
  65% {
    opacity: 1; }
  100% {
    margin-top: 2px;
    opacity: 0; } }

@keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0; }
  15% {
    opacity: 1; }
  50% {
    margin-top: 20px;
    opacity: 0; }
  65% {
    opacity: 1; }
  100% {
    margin-top: 2px;
    opacity: 0; } }
