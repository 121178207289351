/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

button,
input,
optgroup,
select,
option,
textarea {
  font-size: 100%;
  font: inherit;
  background: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none; }

textarea {
  resize: none; }

input:focus, textarea:focus, select:focus, button:focus {
  outline: none; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@offinga button {
  background-color: transparent;
  outline: none;
  border: 0; }

html, body, #root {
  height: 100%;
  width: 100%; }

.cursor-0 {
  cursor: url(./App/assets/cursors/0.png), auto !important; }

.cursor-1 {
  cursor: url(./App/assets/cursors/1.png), auto !important; }

.cursor-2 {
  cursor: url(./App/assets/cursors/2.png), auto !important; }

.cursor-3 {
  cursor: url(./App/assets/cursors/3.png), auto !important; }

.cursor-4 {
  cursor: url(./App/assets/cursors/4.png), auto !important; }

.cursor-5 {
  cursor: url(./App/assets/cursors/5.png), auto !important; }
