.HardDrives {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: black; }
  .HardDrives .Frame {
    pointer-events: all; }
  .HardDrives .bottle-icon, .HardDrives .islandButton {
    font-size: 40px;
    height: 50%;
    width: 100%;
    /* text-align: center;
    height: 56px;
    width: 50px; */ }
  .HardDrives .birds {
    position: absolute;
    top: 0;
    left: 0; }
    .HardDrives .birds .bird {
      max-width: 100%;
      height: auto;
      width: 100%;
      height: 70px;
      background-image: url("./assets/bird_trans.gif");
      background-size: cover;
      /* <------ */
      background-repeat: no-repeat;
      background-position: center center; }
  .HardDrives .flipped {
    transform: scaleX(-1); }
  .HardDrives iframe {
    position: absolute;
    top: 0;
    left: 0; }
  .HardDrives .controller-bar {
    padding: 5px;
    background-image: url(/images/harddrives/clouds.png);
    background-position: 0 0;
    background-repeat: repeat;
    height: 100%;
    width: 100%; }
  .HardDrives input {
    width: 465px; }
