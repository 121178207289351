.Wasted {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: linear-gradient(0deg, #22c1c3 0%, #fdbb2d 100%);
  /* background-image: url("/images/space/2000.png");
  background-repeat: repeat; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .Wasted .bird {
    background-image: url("https://media.giphy.com/media/l3Uchq9s6Hx0aK8F2/giphy.gif");
    background-size: cover;
    /* <------ */
    background-repeat: no-repeat;
    background-position: center center; }
  .Wasted .flipped {
    transform: scaleX(-1); }
  .Wasted .palindrome {
    font-size: 150px;
    font-family: spacetimeFont, Arial, Helvetica, sans-serif;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
    padding-top: 30px;
    top: 0px;
    pointer-events: none;
    z-index: 500;
    width: 100%;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .Wasted .window {
    background-color: transparent; }
  .Wasted .content {
    margin: auto;
    padding: 2px;
    background-color: transparent; }

@font-face {
  font-family: spacetimeFont;
  /* src: url("/fonts/VENTA___.ttf"); */
  /* src: url("/fonts/Modeseven.ttf"); */
  src: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/spacetimes/dogica.ttf"); }
