button.standardButton {
  font-size: 12px;
  padding: 10px;
  min-width: 140px;
  text-align: center;
  color: black;
  border-radius: 6px;
  border: 2px solid black;
  color: white;
  transition: all 0.2s ease-in-out; }
  button.standardButton:hover {
    transform: scale(1.05); }
  button.standardButton.primary {
    background: black;
    color: white; }
  button.standardButton.secondary {
    background-color: white;
    color: black;
    border: 2px solid black; }

input.standardInput {
  font-size: 12px;
  padding: 15px 10px;
  text-align: left;
  border: 2px solid black;
  color: black;
  border-radius: 6px; }

input.standardInputWhite {
  font-size: 12px;
  padding: 15px 10px;
  text-align: left;
  border: 2px solid white;
  color: white;
  border-radius: 6px; }

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: black white; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px; }

*::-webkit-scrollbar-track {
  background: white; }

*::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 20px;
  border: 3px solid white; }

.confession-txt {
  padding: 10px 15px 10px 15px;
  color: #6666cc;
  background: rgba(255, 255, 255, 0.3); }

.DesktopIcon {
  opacity: .8; }

.DesktopIcon .desktop-text {
  box-shadow: -40px -40px 40px #f1eeff;
  font-size: 18px;
  font-family: monospace;
  text-shadow: none; }

.tweet {
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-family: "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  font-size: 10px;
  font-weight: bold;
  line-height: 16px; }
  .tweet hr {
    border: 1px solid #aaaadd88; }
  .tweet .tweet-top {
    display: flex;
    flex-direction: row;
    color: white;
    padding-bottom: 5px; }
    .tweet .tweet-top .tweeter-icon {
      flex: 0 0 auto;
      width: 50px;
      height: 50px;
      border-radius: 50%; }
      .tweet .tweet-top .tweeter-icon div {
        margin: 0 auto;
        height: 100%;
        width: auto; }
    .tweet .tweet-top .tweeter {
      flex: 1 1 auto;
      padding-top: 10px;
      padding-left: 10px;
      align-items: center; }
      .tweet .tweet-top .tweeter div a {
        text-decoration: none; }
        .tweet .tweet-top .tweeter div a:hover {
          text-decoration: underline; }
      .tweet .tweet-top .tweeter .person a {
        color: #6666cc;
        font-size: 18px; }
      .tweet .tweet-top .tweeter .tweet-handle a {
        color: #aaaadd;
        font-size: 14px; }
    .tweet .tweet-top .tweet-time {
      font-size: 20px; }
    .tweet .tweet-top .twitter-icon {
      width: 30px;
      flex: 0 0 auto;
      font-size: 24px;
      color: white; }
      .tweet .tweet-top .twitter-icon:hover {
        color: #aaaaff; }
  .tweet .tweet-content {
    color: #6666cc;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    padding-bottom: 5px; }
    .tweet .tweet-content span {
      vertical-align: baseline; }
    .tweet .tweet-content a {
      color: #111199;
      font-weight: normal;
      text-decoration: none;
      outline: 0 none; }
      .tweet .tweet-content a:hover {
        text-decoration: underline; }
  .tweet .tweet-metrics {
    font-size: 14px;
    color: #6666cc;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch; }
    .tweet .tweet-metrics div {
      flex: 1 1 auto;
      text-align: center;
      line-height: 20px; }
      .tweet .tweet-metrics div span {
        padding-left: 5px;
        text-align: center;
        vertical-align: middle; }
