.Flush .Frame .content .flush {
  pointer-events: all; }

.Flush .flush {
  width: 150px;
  height: 60px; }
  .Flush .flush button {
    color: white;
    transition: transform 1s;
    -moz-transition: transform 1s;
    -webkit-transition: transform 1s;
    transform-origin: top right; }
    .Flush .flush button img {
      width: 100%;
      height: 100%; }
