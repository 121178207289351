button.standardButton {
  font-size: 12px;
  padding: 10px;
  min-width: 140px;
  text-align: center;
  color: black;
  border-radius: 6px;
  border: 2px solid black;
  color: white;
  transition: all 0.2s ease-in-out; }
  button.standardButton:hover {
    transform: scale(1.05); }
  button.standardButton.primary {
    background: black;
    color: white; }
  button.standardButton.secondary {
    background-color: white;
    color: black;
    border: 2px solid black; }

input.standardInput {
  font-size: 12px;
  padding: 15px 10px;
  text-align: left;
  border: 2px solid black;
  color: black;
  border-radius: 6px; }

input.standardInputWhite {
  font-size: 12px;
  padding: 15px 10px;
  text-align: left;
  border: 2px solid white;
  color: white;
  border-radius: 6px; }

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: black white; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px; }

*::-webkit-scrollbar-track {
  background: white; }

*::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 20px;
  border: 3px solid white; }

.ClickMe {
  background-color: black;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  /* cursor: pointer !important; */
  background-repeat: repeat; }
  .ClickMe .Frame .window {
    border-color: #b4fa00; }
    .ClickMe .Frame .window .content {
      pointer-events: none; }
      .ClickMe .Frame .window .content .btn {
        pointer-events: all; }
  .ClickMe .Frame .titlebar {
    border-bottom: 2px solid #b4fa00; }
  .ClickMe .Frame .circleButton {
    background: #b4fa00; }
    .ClickMe .Frame .circleButton:hover {
      background: #b4fa00; }
  .ClickMe .content {
    background-color: rgba(255, 255, 255, 0);
    pointer-events: none; }
  .ClickMe .window {
    background-color: rgba(255, 255, 255, 0); }
  .ClickMe .react-p5 {
    position: absolute;
    width: 100%; }
    .ClickMe .react-p5 canvas {
      width: 100%; }
  .ClickMe .loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: purple;
    z-index: 800; }
  .ClickMe button {
    height: 60px;
    font-size: 50px; }
    .ClickMe button img {
      height: 40px;
      width: 40px; }
  .ClickMe .hands button {
    margin: auto; }

.point0 {
  cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/like.png"), auto; }
  .point0 button {
    cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/like.png"), auto; }

.point1 {
  cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/point1.png"), auto; }
  .point1 button {
    cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/point1.png"), auto; }

.point2 {
  cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/point2.png"), auto; }
  .point2 button {
    cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/point2.png"), auto; }

.point3 {
  cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/point3.png"), auto; }
  .point3 button {
    cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/point3.png"), auto; }

.point4 {
  cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/point4.png"), auto; }
  .point4 button {
    cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/point4.png"), auto; }

.kiss {
  cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/kiss.png"), auto; }
  .kiss button {
    cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/kiss.png"), auto; }

.erase {
  cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/eraser.png"), auto; }
  .erase button {
    cursor: url("https://lmd-bucket.s3.us-east-2.amazonaws.com/sketches/clickMe/cursors/eraser.png"), auto; }

/* https://media.giphy.com/media/3o7btNNSCfjnn14imk/giphy.gif */
/* peeps glitch https://media.giphy.com/media/2shBNJSPTrpWvoXfpD/source.gif */
