.Instructions-list {
  text-align: center;
  overflow: auto;
  border: 2px solid black;
  pointer-events: all;
  border-radius: 6px; }
  .Instructions-list .instruction {
    text-align: left;
    display: flex;
    padding: 20px;
    flex-direction: row;
    flex-wrap: wrap; }
    .Instructions-list .instruction h3 {
      border-bottom: 1px solid #cfcfcf;
      margin-bottom: 5px; }
    .Instructions-list .instruction p {
      line-height: 14px; }
    .Instructions-list .instruction .faqImg {
      flex: 1;
      padding-right: 20px; }
      .Instructions-list .instruction .faqImg img {
        border-radius: 6px;
        border: 2px solid black;
        width: 100%; }
    .Instructions-list .instruction .instruction-txt {
      flex: 1; }
      .Instructions-list .instruction .instruction-txt h3 {
        font-size: 14px;
        padding-bottom: 8px; }
      .Instructions-list .instruction .instruction-txt p {
        font-size: 10px; }
