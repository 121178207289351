.LoadingPage {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-weight: normal; }
  .LoadingPage .title {
    font-size: 50px;
    padding-bottom: 20px; }
  .LoadingPage .loadingSymbol {
    font-size: 20px; }
