.SignIn {
  position: absolute; }
  .SignIn .Frame .window {
    background: white; }
    .SignIn .Frame .window .content {
      pointer-events: all; }

.SignInForm .userBar {
  height: 38px;
  align-self: flex-start; }
  .SignInForm .userBar .avatar {
    font-size: 36px;
    height: 38px;
    width: 38px;
    padding-right: 15px; }
  .SignInForm .userBar input {
    border-bottom: 2px solid black;
    font-size: 16px;
    width: 225px; }

.SignInForm .emoji-list {
  border: 2px solid black;
  border-radius: 6px; }
  .SignInForm .emoji-list button {
    font-size: 18px;
    width: 24px;
    height: 24px;
    padding: 2px;
    border: none; }
