.Page {
  color: white;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  line-height: 140%;
  background-image: url("./assets/wallpaper3.jpg");
  background-repeat: repeat;
  background-size: 350px 250px;
  overflow: auto; }
  .Page a {
    color: cyan; }
  .Page h1 {
    font-size: 34px;
    padding: 20px 0 20px 0;
    line-height: 60px; }
  .Page h2 {
    font-size: 24px;
    padding: 10px 0 10px 0;
    line-height: 24px; }
  .Page h3 {
    font-size: 18px;
    line-height: 24px; }
  .Page p {
    font-size: 12px;
    line-height: 20px;
    padding: 15px 0 15px 0; }
  .Page ul {
    margin-left: 0;
    padding-left: 40px; }
    .Page ul li {
      padding: 5px 0 5px 0; }
  .Page .container {
    flex: 1;
    padding-top: 50px;
    padding-bottom: 70px;
    width: 87%;
    max-width: 900px;
    margin: auto;
    box-sizing: border-box; }

.Page.xxsmall .container {
  padding: 15px;
  padding-bottom: 70px;
  width: 100%; }

.Page.xxsmall h1 {
  font-size: 24px;
  padding: 20px 0 20px 0;
  line-height: 40px; }

.Page.xxsmall h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  line-height: 24px; }

.Page.xxsmall h3 {
  font-size: 18px;
  line-height: 24px; }

.Page.xxsmall p {
  font-size: 12px;
  line-height: 20px;
  padding: 15px 0 15px 0; }

.Page.landscape .container {
  padding-right: 40px; }

.NotFound {
  color: white; }
  .NotFound .container {
    margin: auto auto; }
    .NotFound .container h1 {
      font-size: 30px;
      line-height: 36px; }
