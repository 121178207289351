button.standardButton {
  font-size: 12px;
  padding: 10px;
  min-width: 140px;
  text-align: center;
  color: black;
  border-radius: 6px;
  border: 2px solid black;
  color: white;
  transition: all 0.2s ease-in-out; }
  button.standardButton:hover {
    transform: scale(1.05); }
  button.standardButton.primary {
    background: black;
    color: white; }
  button.standardButton.secondary {
    background-color: white;
    color: black;
    border: 2px solid black; }

input.standardInput {
  font-size: 12px;
  padding: 15px 10px;
  text-align: left;
  border: 2px solid black;
  color: black;
  border-radius: 6px; }

input.standardInputWhite {
  font-size: 12px;
  padding: 15px 10px;
  text-align: left;
  border: 2px solid white;
  color: white;
  border-radius: 6px; }

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: black white; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px; }

*::-webkit-scrollbar-track {
  background: white; }

*::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 20px;
  border: 3px solid white; }

.confession-bottomBar {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f1eeff), color-stop(100%, #1e03fc42));
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }
  .confession-bottomBar button {
    margin-top: 5px;
    width: 50px;
    height: 50px;
    font-size: 24px;
    background: transparent; }
  .confession-bottomBar .vidButton {
    color: #6666cc; }
  .confession-bottomBar .bar-emojis {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .confession-bottomBar .bar-emojis button {
      flex: 0 0 auto; }
      .confession-bottomBar .bar-emojis button img {
        width: 35px;
        height: 35px; }
  .confession-bottomBar .bar-good-bad {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 0 0 auto; }
    .confession-bottomBar .bar-good-bad img {
      width: 35px;
      height: 35px; }

.confession-emojis {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }
  .confession-emojis img {
    position: absolute;
    height: 50px;
    width: 50px; }
