button.standardButton {
  font-size: 12px;
  padding: 10px;
  min-width: 140px;
  text-align: center;
  color: black;
  border-radius: 6px;
  border: 2px solid black;
  color: white;
  transition: all 0.2s ease-in-out; }
  button.standardButton:hover {
    transform: scale(1.05); }
  button.standardButton.primary {
    background: black;
    color: white; }
  button.standardButton.secondary {
    background-color: white;
    color: black;
    border: 2px solid black; }

input.standardInput {
  font-size: 12px;
  padding: 15px 10px;
  text-align: left;
  border: 2px solid black;
  color: black;
  border-radius: 6px; }

input.standardInputWhite {
  font-size: 12px;
  padding: 15px 10px;
  text-align: left;
  border: 2px solid white;
  color: white;
  border-radius: 6px; }

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: black white; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px; }

*::-webkit-scrollbar-track {
  background: white; }

*::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 20px;
  border: 3px solid white; }

.clickMe .Header {
  border: 2px solid #b4fa00; }

.clickMe .MobileFooter {
  border: 2px solid #b4fa00; }

.MobileFooter {
  position: fixed;
  background-color: black;
  color: white;
  z-index: 2000;
  box-sizing: border-box;
  bottom: 0px;
  height: 60px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch; }
  .MobileFooter.landscape {
    position: fixed;
    height: 100%;
    right: 0px;
    left: auto;
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: stretch; }
    .MobileFooter.landscape .top {
      margin-top: 60px; }
    .MobileFooter.landscape .notification .badge {
      left: 28px; }
  .MobileFooter .notification {
    position: relative; }
    .MobileFooter .notification .badge {
      position: absolute;
      font-size: 10px;
      top: -40px;
      left: 42px;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 50%;
      background: red;
      color: white; }
  .MobileFooter button {
    color: white;
    flex: 1;
    font-size: 30px; }
  .MobileFooter .disabled {
    color: rgba(255, 255, 255, 0.4); }
  .MobileFooter .opened {
    color: white; }
  .MobileFooter .closed {
    color: rgba(255, 255, 255, 0.4); }
