.GrayedOut {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  pointer-events: all; }

.CenterModal .CenterModal-Container {
  display: flex;
  flex-direction: column;
  pointer-events: all; }

.CenterModal .flex1 {
  flex: 1;
  overflow: auto; }

.CenterModal .flexRow {
  display: flex;
  flex-direction: row; }

.CenterModal .flexPad {
  margin-bottom: 15px; }

.CenterModal .center-buttons {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  justify-content: center; }
  .CenterModal .center-buttons button {
    margin: 5px; }

@media only screen and (max-width: 350px) {
  .CenterModal button.standardButton {
    min-width: 120px; } }

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%; }
