button.standardButton {
  font-size: 12px;
  padding: 10px;
  min-width: 140px;
  text-align: center;
  color: black;
  border-radius: 6px;
  border: 2px solid black;
  color: white;
  transition: all 0.2s ease-in-out; }
  button.standardButton:hover {
    transform: scale(1.05); }
  button.standardButton.primary {
    background: black;
    color: white; }
  button.standardButton.secondary {
    background-color: white;
    color: black;
    border: 2px solid black; }

input.standardInput {
  font-size: 12px;
  padding: 15px 10px;
  text-align: left;
  border: 2px solid black;
  color: black;
  border-radius: 6px; }

input.standardInputWhite {
  font-size: 12px;
  padding: 15px 10px;
  text-align: left;
  border: 2px solid white;
  color: white;
  border-radius: 6px; }

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: black white; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px; }

*::-webkit-scrollbar-track {
  background: white; }

*::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 20px;
  border: 3px solid white; }

.expandable:hover {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fc03ad), color-stop(100%, #335ef6));
  border-bottom: 2px solid #335ef6;
  height: 34px; }

.clickable:hover {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fc03ad), color-stop(100%, #335ef6));
  border-bottom: 2px solid #335ef6;
  height: 34px; }

.mobile .expandable,
.mobile .clickable,
.mobile .selected {
  height: 60px; }

.mobile .expandable:hover,
.mobile .clickable:hover,
.mobile .selected:hover {
  height: 60px; }

/*--Header--*/
/* a3a3a3 */
.Header {
  /* background: linear-gradient(to bottom, #e6e6e6 0%, #c3c3c3 100%); */
  /* background: white; */
  display: block;
  font-family: consoleFont;
  border: 2px solid black;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
  font-size: 16px;
  height: 34px;
  color: white;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0px 0px 20px #000000;
  z-index: 2200;
  flex: 1 1 100%;
  /* Tooltip container */
  /* Tooltip text */
  /* Tooltip arrow */
  /* Show the tooltip text when you mouse over the tooltip container */ }
  .Header.mobile {
    height: 60px; }
    .Header.mobile ul li {
      line-height: 60px; }
    .Header.mobile .submenu ul {
      top: 58px; }
    .Header.mobile .right .submenu ul {
      width: 140px;
      border-top-left-radius: 8px;
      border-top-right-radius: 0px;
      transform: translate(-95px, 0px); }
    .Header.mobile .apple {
      font-size: 26px; }
    .Header.mobile .arrow {
      font-size: 22px; }
    .Header.mobile .arrow.expandable {
      padding: 0px;
      padding-left: 4px;
      padding-right: 4px; }
    .Header.mobile .xxsmall {
      line-height: 60px;
      height: 60px; }
  .Header .currentPage {
    color: #335ef6dd; }
  .Header .disabled {
    color: rgba(255, 255, 255, 0.4); }
  .Header .opened {
    color: white; }
  .Header .closed {
    color: rgba(255, 255, 255, 0.4); }
  .Header .icon {
    font-size: 20px;
    line-height: 34px; }
    .Header .icon svg {
      vertical-align: middle; }
    .Header .icon.notify {
      padding-right: 20px; }
  .Header .liveStreamOn {
    color: pink; }
  .Header .selected {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fc03ad), color-stop(100%, #335ef6));
    color: #fff;
    border-bottom: 0.1em solid #335ef6;
    height: 34px; }
  .Header ul {
    margin: 0 5px; }
    .Header ul .hidden {
      display: none; }
    .Header ul .arrow.expandable {
      color: #335ef6; }
      .Header ul .arrow.expandable:hover {
        color: white; }
    .Header ul li {
      display: inline-block;
      line-height: 34px;
      padding: 0 0.5em;
      position: relative;
      box-sizing: border-box; }
      .Header ul li a {
        color: white; }
    .Header ul a {
      color: #000;
      text-decoration: none; }
    .Header ul.left {
      float: left;
      white-space: nowrap; }
    .Header ul.right {
      float: right; }
  .Header .fa {
    color: white; }
  .Header .submenu {
    display: none; }
    .Header .submenu .spacer {
      background: rgba(211, 211, 211, 0.8);
      height: 1px !important;
      margin: 2px 0;
      padding: 0;
      width: 102%;
      position: relative; }
      .Header .submenu .spacer:hover {
        background: rgba(211, 211, 211, 0.8); }
    .Header .submenu input[type="text"] {
      border-radius: 2em;
      border: 1px solid #333;
      height: 2em;
      padding: 0 10px; }
    .Header .submenu input[type="range"] {
      transform: rotate(-90deg);
      margin-left: -50px;
      margin-top: 65px; }
    .Header .submenu ul {
      background: black;
      white-space: normal;
      border: white solid 2px;
      border-radius: 0 8px 8px 8px;
      color: white;
      font-size: 14px;
      left: 0px;
      margin: 0 !important;
      overflow: hidden;
      position: absolute;
      top: 32px;
      width: auto;
      white-space: nowrap;
      z-index: 200; }
      .Header .submenu ul.mobile {
        max-height: 250px; }
        .Header .submenu ul.mobile li {
          border-bottom: 1px solid white;
          padding: 8px 8px 8px 15px;
          white-space: normal; }
      .Header .submenu ul a:last-child li {
        border-bottom: 0px; }
      .Header .submenu ul li {
        display: block !important;
        vertical-align: middle;
        float: none;
        margin-right: 75px;
        font-weight: 400;
        padding: 3px 3px 3px 15px;
        width: 100%;
        color: white;
        line-height: 1.8em; }
        .Header .submenu ul li:hover:not(.volume) {
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fc03ad), color-stop(100%, #335ef6));
          color: #fff; }
        .Header .submenu ul li.shortcut:after {
          content: attr(shortcut);
          float: right;
          padding-right: 20px; }
        .Header .submenu ul li.noShortcut {
          margin-right: 0px; }
    .Header .submenu .hasSubMenu:after {
      content: "\f04b";
      font-family: FontAwesome;
      font-size: 0.8em;
      display: inline-block;
      position: absolute;
      right: 25px; }
  .Header .flexRow {
    display: flex;
    flex-direction: row; }
    .Header .flexRow div {
      padding-right: 5px; }
  .Header .flex1 {
    flex: 1; }
  .Header .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */ }
  .Header .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 50%;
    margin-left: -60px;
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s; }
  .Header .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent; }
  .Header .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1; }
  .Header .hamburger-button {
    color: white;
    background: red;
    border: 1px solid white;
    border-radius: 4px;
    margin-top: -1px;
    vertical-align: middle; }
  .Header .glitz {
    background: linear-gradient(270deg, #00ffbd, #e98fea, #8fcdea);
    background-size: 600% 600%;
    -webkit-animation: ClickMeGradient 0.6s ease infinite;
    -moz-animation: ClickMeGradient 0.6s ease infinite;
    animation: ClickMeGradient 0.6s ease infinite; }
  .Header .notification {
    position: relative; }
    .Header .notification .badge {
      position: absolute;
      font-size: 10px;
      top: -30px;
      left: 18px;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 50%;
      background: red;
      color: white; }

@-webkit-keyframes ClickMeGradient {
  0% {
    background-position: 0% 48%; }
  50% {
    background-position: 100% 53%; }
  100% {
    background-position: 0% 48%; } }

@-moz-keyframes ClickMeGradient {
  0% {
    background-position: 0% 48%; }
  50% {
    background-position: 100% 53%; }
  100% {
    background-position: 0% 48%; } }

@keyframes ClickMeGradient {
  0% {
    background-position: 0% 48%; }
  50% {
    background-position: 100% 53%; }
  100% {
    background-position: 0% 48%; } }
  @media screen and (max-width: 830px) {
    .Header .submenu ul {
      font-size: 12px;
      overflow-y: auto;
      overflow-x: hidden; } }
  @media screen and (max-width: 400px) {
    .Header {
      font-size: 14px; }
      .Header .submenu ul {
        font-size: 12px; } }
  @media screen and (max-width: 340px) {
    .Header {
      font-size: 12px; }
      .Header .submenu ul {
        font-size: 8px; } }
  @media screen and (max-width: 314px) {
    .Header {
      font-size: 10px; }
      .Header .submenu ul {
        font-size: 10px; } }

.Header.menuTheme.clickMe {
  border-color: #b4fa00; }
  .Header.menuTheme.clickMe .submenu ul {
    border-color: #b4fa00; }

.BackHeader {
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  z-index: 499;
  width: 100%;
  height: 10px; }

/*--Menus--*/
.visible {
  display: block !important; }

.search {
  padding: 5px 10px !important; }

.noChoice {
  color: #adadad; }

.noChoice:hover {
  background: #fff !important;
  color: #adadad !important; }

#volume-icon-li {
  width: 1em; }

.focus img {
  border: 2px solid rgba(225, 225, 225, 0.5) !important;
  border-radius: 6px; }

span.focus:after {
  background: #146ece;
  border-radius: 15px;
  text-shadow: none !important; }

span#pageTitle {
  white-space: nowrap; }
